// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-blog-page-template-js": () => import("./../../../src/templates/blogPageTemplate.js" /* webpackChunkName: "component---src-templates-blog-page-template-js" */),
  "component---src-templates-blog-template-js": () => import("./../../../src/templates/blogTemplate.js" /* webpackChunkName: "component---src-templates-blog-template-js" */),
  "component---src-templates-contact-template-js": () => import("./../../../src/templates/contactTemplate.js" /* webpackChunkName: "component---src-templates-contact-template-js" */),
  "component---src-templates-page-template-js": () => import("./../../../src/templates/pageTemplate.js" /* webpackChunkName: "component---src-templates-page-template-js" */),
  "component---src-templates-portfolio-template-js": () => import("./../../../src/templates/portfolioTemplate.js" /* webpackChunkName: "component---src-templates-portfolio-template-js" */),
  "component---src-templates-pricing-template-js": () => import("./../../../src/templates/pricingTemplate.js" /* webpackChunkName: "component---src-templates-pricing-template-js" */),
  "component---src-templates-two-column-template-js": () => import("./../../../src/templates/twoColumnTemplate.js" /* webpackChunkName: "component---src-templates-two-column-template-js" */)
}

